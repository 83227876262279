import { uciChar } from './uciChar';
import { parseUci, makeUci } from 'chessops';
import { normalizeMove } from 'chessops/chess';
export * from './sanWriter';
export const initialFen = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';
export function fixCrazySan(san) {
    return san[0] === 'P' ? san.slice(1) : san;
}
export function destsToUcis(dests) {
    const ucis = [];
    for (const [orig, d] of dests) {
        d.forEach(function (dest) {
            ucis.push(orig + dest);
        });
    }
    return ucis;
}
export function readDests(lines) {
    if (typeof lines === 'undefined')
        return null;
    const dests = new Map();
    if (lines)
        for (const line of lines.split(' ')) {
            dests.set(uciChar[line[0]], line
                .slice(1)
                .split('')
                .map(c => uciChar[c]));
        }
    return dests;
}
export function readDrops(line) {
    if (typeof line === 'undefined' || line === null)
        return null;
    return line.match(/.{2}/g) || [];
}
export function normalMove(chess, unsafeUci) {
    const unsafe = parseUci(unsafeUci);
    const move = unsafe && 'from' in unsafe ? { ...unsafe, ...normalizeMove(chess, unsafe) } : undefined;
    return move && chess.isLegal(move) ? { uci: makeUci(move), move } : undefined;
}
export const altCastles = {
    e1a1: 'e1c1',
    e1h1: 'e1g1',
    e8a8: 'e8c8',
    e8h8: 'e8g8',
};
// we must strive to redefine roles and promotions in each and every module
export const from = (uci) => uci.slice(0, 2);
export const to = (uci) => uci.slice(2, 4);
export const Roles = ['king', 'queen', 'rook', 'bishop', 'knight', 'pawn'];
export const RoleChars = ['K', 'Q', 'R', 'B', 'N', 'P'];
export const roleChar = (role) => ({
    king: 'K',
    queen: 'Q',
    rook: 'R',
    bishop: 'B',
    knight: 'N',
    pawn: 'P',
})[role];
export const charRole = (char) => ({
    P: 'pawn',
    N: 'knight',
    B: 'bishop',
    R: 'rook',
    Q: 'queen',
    K: 'king',
})[char];
export const promo = (uci) => charRole(uci.slice(4, 5).toUpperCase());
